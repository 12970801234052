export type InformationModalType = 'screenshare-permission';

export interface InformationModalState {
  readonly modalType: InformationModalType;
  readonly title: string;
  readonly description: string;
  readonly image: string;
  readonly buttonText: string;
}

export enum InformationModalActionTypes {
  OPEN_INFORMATION_MODAL = '[Information Modal] Open Information Modal',
  CLOSE_INFORMATION_MODAL = '[Information Modal] Close Information Modal',
}

interface OpenInformationModalAction {
  readonly type: typeof InformationModalActionTypes.OPEN_INFORMATION_MODAL;
  readonly informationModal: InformationModalState;
}

interface CloseInformationModalAction {
  readonly type: typeof InformationModalActionTypes.CLOSE_INFORMATION_MODAL;
}

export type InformationModalActions =
  | OpenInformationModalAction
  | CloseInformationModalAction;
