import { OrganizationMember } from '@dashboard/types/organization_member';

export interface AuthState {
  readonly accountId: string;
  readonly availableOrganizationMembers: Array<OrganizationMember>;
  readonly currentOrganizationMember: OrganizationMember;
}

export enum AuthActionTypes {
  SET_AVAILABLE_ORGANIZATION_MEMBERS = '[Dashboard Auth] Set Available Organization Members',
  SET_CURRENT_ORGANIZATION_MEMBER = '[Dashboard Auth] Set Current Organization Member',
  SET_ACCOUNT_ID = '[Dashboard Auth] Set Account Id',
}

export interface SetAvailableOrganizationMembersAction {
  readonly type: typeof AuthActionTypes.SET_AVAILABLE_ORGANIZATION_MEMBERS;
  readonly availableOrganizationMembers: Array<OrganizationMember>;
}

export interface SetCurrentOrganizationMemberAction {
  readonly type: typeof AuthActionTypes.SET_CURRENT_ORGANIZATION_MEMBER;
  readonly currentOrganizationMember: OrganizationMember | null;
}

export interface SetAccountIdAction {
  readonly type: typeof AuthActionTypes.SET_ACCOUNT_ID;
  readonly accountId: string;
}

export type AuthActions =
  | SetAccountIdAction
  | SetAvailableOrganizationMembersAction
  | SetCurrentOrganizationMemberAction;
