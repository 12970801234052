import { getRouteDashboardIndex } from '@dashboard/routes';
import loadable from '@loadable/component';
import { MemorizeDevice } from '@venue/common/MemorizeDevice';
import { Loader } from '@venue/components/atoms/Loader';
import { UnsupportedBrowser } from '@venue/components/UnsupportedBrowser';
import { useBrowserSupport } from '@venue/hooks/useBrowserSupport';
import { history } from '@venue/store';
import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import './GlobalRouter.scss';

// https://loadable-components.com
const LiveRouter = loadable(() => import('./LiveRouter'));
const DashboardRouter = loadable(
  () => import('@dashboard/pages/DashboardRouter')
);

const GlobalRouter = () => {
  const { isBrowserSupported } = useBrowserSupport();

  if (!isBrowserSupported) {
    return <UnsupportedBrowser />;
  }

  return (
    <div className='tw-h-full'>
      <MemorizeDevice />
      <Router history={history}>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route
              path='/organizations/*'
              element={<Navigate to={getRouteDashboardIndex()} replace />}
            />
            <Route path='/dashboard/*' element={<DashboardRouter />} />
            <Route
              path=''
              element={<Navigate to={getRouteDashboardIndex()} replace />}
            />
            <Route path='/*' element={<LiveRouter />} />
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
};

export default GlobalRouter;
