import { RequestStatus } from '@venue/types/device';
import { Reducer } from 'redux';
import { DeviceActions, DeviceActionTypes, DeviceState } from './types';

export const initialState: DeviceState = {
  cameraLabel: '',
  microphoneLabel: '',
  speakersLabel: '',
  devices: [],
  requestStatus: RequestStatus.Unknown,
  locale: 'en-CA',
  hasDetectedLocale: false,
  showEmojiCannon: true,
  showAudienceGallery: true,
};

export const device: Reducer<DeviceState> = (
  state: DeviceState = initialState,
  action: DeviceActions
) => {
  switch (action.type) {
    case DeviceActionTypes.SET_CAMERA:
      return {
        ...state,
        cameraLabel: action.deviceLabel,
      };
    case DeviceActionTypes.SET_MICROPHONE:
      return {
        ...state,
        microphoneLabel: action.deviceLabel,
      };
    case DeviceActionTypes.SET_SPEAKERS:
      return {
        ...state,
        speakersLabel: action.deviceLabel,
      };
    case DeviceActionTypes.SET_REQUEST_STATUS:
      return {
        ...state,
        requestStatus: action.requestStatus,
      };
    case DeviceActionTypes.SET_DEVICES:
      return {
        ...state,
        devices: action.devices,
      };
    case DeviceActionTypes.SET_LOCALE:
      return {
        ...state,
        locale: action.locale,
        hasDetectedLocale: true,
      };
    case DeviceActionTypes.SHOW_EMOJI_CANNON:
      return {
        ...state,
        showEmojiCannon: action.show,
      };
    case DeviceActionTypes.SHOW_AUDIENCE_GALLERY:
      return {
        ...state,
        showAudienceGallery: action.show,
      };
    default:
      return state;
  }
};
