export enum StageState {
  Backstage = 'backstage',
  Breakout = 'breakout',
  CheckIn = 'check_in',
  Ended = 'ended',
  MainStage = 'mainstage',
  Networking = 'networking',
  PreEvent = 'pre_event',
  RoomGroup = 'room_group',
  SystemCheck = 'systemcheck',
  Welcome = 'welcome',
}

export enum StageViewState {
  Idle = 'idle',
  Active = 'active',
  SystemCheck = 'system_check',
}

export enum ScreenShareStatus {
  Available = 'available',
  LocallyBusy = 'locally_busy',
  RemotelyBusy = 'remotely_busy',
  Unavailable = 'unavailable',
}

export enum ContentShareStatus {
  Available = 'available',
  Busy = 'busy',
  Unavailable = 'unavailable',
}
