import { LocalStreamState } from '@venue/types/agora';
import { RecordingStatus } from '@venue/types/recording';
import { ScreenShareStatus } from '@venue/types/stage';

export enum StreamType {
  LocalCamera = 'Local Camera Stream',
  RemoteCamera = 'Remote Camera Stream',
  LocalScreen = 'Local Screen Stream',
  RemoteScreen = 'Remote Screen Stream',
}

export interface StreamsState {
  // for now, we are explicitly storing different types of streams as separate attributes
  // in the future, they will be in a generic "streams" collection
  // this is because cleanup will be easier if we restrict
  // the number of local and screenshare streams to 1
  readonly clientId: string;
  readonly localStreamId: string;
  readonly remoteStreamIds: ReadonlyArray<string>;

  readonly screenShareClientId: string;
  readonly localScreenStreamId: string;
  readonly remoteScreenStreamId: string;

  readonly localStreamState: LocalStreamState;
  readonly screenShareStatus: ScreenShareStatus;
  readonly audioMutedMap: Record<string, boolean>;
  readonly videoMutedMap: Record<string, boolean>;
  readonly disconnectedMap: Record<string, boolean>;
  readonly recordingStatuses: Array<RecordingStatus>;
  readonly channelName: string;
}

export enum StreamsActionTypes {
  SET_AGORA_CLIENT = '[Streams] Set Agora Client',
  SET_SCREEN_SHARE_AGORA_CLIENT = '[Streams] Set Screen Share Agora Client',
  SET_LOCAL_STREAM = '[Streams] Set Local Stream',
  SET_LOCAL_STREAM_STATE = '[Stage] Set Local Stream State',
  SET_LOCAL_SCREEN_STREAM = '[Streams] Set Local Screen Stream',
  SET_SCREEN_SHARE_STATUS = '[Stage] Set Screen Share Status',
  SET_REMOTE_STREAMS = '[Streams] Set Remote Streams',
  SET_REMOTE_SCREEN_STREAM = '[Streams] Set Remote Screen Stream',
  SET_AUDIO_MUTED_MAP = '[Streams] Set Audio Muted Map',
  SET_VIDEO_MUTED_MAP = '[Streams] Set Video Muted Map',
  SET_DISCONNECTED_MAP = '[Streams] Set Disconnected Map',
  SET_RECORDING_STATUSES = '[Streams] Set Recording Statuses',
  SET_CHANNEL_NAME = '[Streams] Set Channel Name',
  CLEAR_AUDIO_MUTED_MAP = '[Streams] Clear Audio Muted Map',
  CLEAR_VIDEO_MUTED_MAP = '[Streams] Clear Video Muted Map',
}

export interface SetAgoraClientAction {
  readonly type: typeof StreamsActionTypes.SET_AGORA_CLIENT;
  readonly clientId: string;
}

interface SetScreenShareAgoraClientAction {
  readonly type: typeof StreamsActionTypes.SET_SCREEN_SHARE_AGORA_CLIENT;
  readonly clientId: string;
}

export interface SetLocalStreamAction {
  readonly type: typeof StreamsActionTypes.SET_LOCAL_STREAM;
  readonly streamId: string;
}

export interface SetLocalStreamStateAction {
  readonly type: typeof StreamsActionTypes.SET_LOCAL_STREAM_STATE;
  readonly state: LocalStreamState;
}

export interface SetLocalScreenStreamAction {
  readonly type: typeof StreamsActionTypes.SET_LOCAL_SCREEN_STREAM;
  readonly streamId: string;
}

export interface SetRemoteStreamsAction {
  readonly type: typeof StreamsActionTypes.SET_REMOTE_STREAMS;
  readonly streamIds: Array<string>;
}

export interface SetRemoteScreenStreamAction {
  readonly type: typeof StreamsActionTypes.SET_REMOTE_SCREEN_STREAM;
  readonly streamId: string;
}

export interface SetAudioMutedMapAction {
  readonly type: typeof StreamsActionTypes.SET_AUDIO_MUTED_MAP;
  readonly streamId: string;
  readonly isMuted: boolean;
}

export interface ClearAudioMutedMapAction {
  readonly type: typeof StreamsActionTypes.CLEAR_AUDIO_MUTED_MAP;
}

export interface SetVideoMutedMapAction {
  readonly type: typeof StreamsActionTypes.SET_VIDEO_MUTED_MAP;
  readonly streamId: string;
  readonly isMuted: boolean;
}

export interface ClearVideoMutedMapAction {
  readonly type: typeof StreamsActionTypes.CLEAR_VIDEO_MUTED_MAP;
}

export interface SetDisconnectedMapAction {
  readonly type: typeof StreamsActionTypes.SET_DISCONNECTED_MAP;
  readonly disconnectedMap: Record<string, boolean>;
}

export interface SetScreenShareStatusAction {
  readonly type: typeof StreamsActionTypes.SET_SCREEN_SHARE_STATUS;
  readonly status: ScreenShareStatus;
}

export interface SetRecordingStatusesAction {
  readonly type: typeof StreamsActionTypes.SET_RECORDING_STATUSES;
  readonly statuses: Array<RecordingStatus>;
}

export interface SetChannelName {
  readonly type: typeof StreamsActionTypes.SET_CHANNEL_NAME;
  readonly channelName: string;
}

export type StreamsActions =
  | SetAgoraClientAction
  | SetScreenShareAgoraClientAction
  | SetScreenShareStatusAction
  | SetLocalStreamAction
  | SetLocalStreamStateAction
  | SetLocalScreenStreamAction
  | SetRemoteStreamsAction
  | SetRemoteScreenStreamAction
  | SetAudioMutedMapAction
  | ClearAudioMutedMapAction
  | SetVideoMutedMapAction
  | ClearVideoMutedMapAction
  | SetDisconnectedMapAction
  | SetScreenShareStatusAction
  | SetRecordingStatusesAction
  | SetChannelName;
