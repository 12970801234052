import {
  ENV_APP_ENV,
  ENV_LOGROCKET_APP_ID,
  ENV_POSTHOG_API_HOST,
  ENV_POSTHOG_TOKEN,
} from './env';

export const IS_PRODUCTION = ENV_APP_ENV === 'production';
export const LOGROCKET_APP_ID = ENV_LOGROCKET_APP_ID || '';
export const POSTHOG_TOKEN = ENV_POSTHOG_TOKEN || '';
export const POSTHOG_API_HOST = ENV_POSTHOG_API_HOST || '';

export const IS_LOGROCKET_ENABLED = IS_PRODUCTION;

export const IS_POSTHOG_ENABLED = IS_PRODUCTION;
