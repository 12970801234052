import { StageState } from '@venue/types/stage';
import axios from 'axios';
import { API_URL } from './venue';

export const getEvent = (eventId: string) => {
  return axios.get(`${API_URL}/events/${eventId}`);
};

export const getOrganizationEventUrl = (
  organizationSlug: string,
  eventSlug: string
) => `${API_URL}/organizations/${organizationSlug}/events/${eventSlug}`;

export const updateEventState = async ({
  eventShareableId,
  state,
}: {
  eventShareableId: string;
  state: StageState;
}) => {
  axios.put(`${API_URL}/events/${eventShareableId}`, { state });
};

export const updateEventNetworkingDuration = async (
  eventShareableId: string,
  networkingDuration: number
) =>
  axios.patch(`${API_URL}/events/${eventShareableId}`, {
    networking_duration: networkingDuration,
  });

export const updateEventThemeColors = async (
  eventShareableId: string,
  titleColor: string,
  themeColor: string
) =>
  axios.patch(`${API_URL}/events/${eventShareableId}`, {
    title_color: titleColor,
    theme_color: themeColor,
  });

export const updateEventHeader = async (
  eventShareableId: string,
  headerImage: string
) =>
  axios.patch(`${API_URL}/events/${eventShareableId}`, {
    header_image: headerImage,
  });

export const createSignInLink = async ({
  eventShareableId,
  email,
  name,
}: {
  eventShareableId: string;
  email: string;
  name: string;
}) =>
  axios.post(`${API_URL}/events/${eventShareableId}/sign_in_link`, {
    email,
    name,
  });

export const federatedLogin = async (
  eventShareableId: string,
  idToken: string
) =>
  axios.post(`${API_URL}/events/${eventShareableId}/federated_login`, {
    id_token: idToken,
  });
