import { translateDeviceLabelToId } from '@venue/features/audioVideoDevices/audioVideoDevices';
import {
  createRtcService,
  CreateRtcServiceArgs,
} from '@venue/services/rtc/rtc.service';
import { RoomType } from '@venue/services/rtc/types';
import { RootState } from '@venue/store/types';
import { DeviceTypes } from '@venue/types/device';
import { MediaType } from '@venue/types/stream';
import { ApplicationThunkDispatch, ExtraArgument } from '@venue/types/thunk';
import { formatFirestoreError } from '@venue/utils/firestoreError';
import { updateEventNetworkingDuration } from '../../services/event.service';
import { speakersLabelSelector } from '../device/selectors';
import {
  MuteLocalAudioAction,
  MuteLocalVideoAction,
  MuteRemoteAudioAction,
  MuteRemoteVideoAction,
  NetworkingActionTypes,
  ResetNetworkingForNextMatchAction,
  ResetRemoteNetworkingAction,
  SetLocalStreamAction,
  SetRemoteStreamAction,
  UnmuteLocalAudioAction,
  UnmuteLocalVideoAction,
  UnmuteRemoteAudioAction,
  UnmuteRemoteVideoAction,
} from './types';

const setLocalStreamId: (streamId: string) => SetLocalStreamAction = (
  streamId
) => ({
  type: NetworkingActionTypes.SET_LOCAL_STREAM,
  streamId,
});

export const playLocalStream = (domId: string) => {
  return (
    _: ApplicationThunkDispatch,
    getState: () => RootState,
    { getRtc }: ExtraArgument
  ) => {
    const streamId = getState().networking.localStreamId;
    if (streamId) {
      getRtc().networkingRtcService.playStream({ streamId, domId });
    }
  };
};

export const updateRemoteStreamId =
  () =>
  (
    dispatch: ApplicationThunkDispatch,
    _: () => RootState,
    { getRtc }: ExtraArgument
  ) => {
    const { networkingRtcService } = getRtc();
    const remoteStreamIds = networkingRtcService?.getRemoteStreamIds() || [];
    const remoteStreamId = remoteStreamIds[0] || null;

    dispatch(setRemoteStreamId(remoteStreamId));
  };

const setRemoteStreamId: (streamId: string | null) => SetRemoteStreamAction = (
  streamId
) => ({
  type: NetworkingActionTypes.SET_REMOTE_STREAM,
  streamId,
});

export const playRemoteStream = (domId: string, mediaType?: MediaType) => {
  return async (
    _: ApplicationThunkDispatch,
    getState: () => RootState,
    { getRtc }: ExtraArgument
  ) => {
    const state = getState();
    const streamId = state.networking.remoteStreamId;
    const speakersLabel = speakersLabelSelector(state);
    const playbackDeviceId = await translateDeviceLabelToId(
      speakersLabel,
      DeviceTypes.AudioOutput
    );
    if (streamId) {
      getRtc().networkingRtcService.playStream({
        streamId,
        domId,
        mediaType,
        playbackDeviceId,
      });
    }
  };
};

export const resetNetworkingForNextMatch =
  () => (dispatch: ApplicationThunkDispatch, _: () => RootState) => {
    dispatch(resetRtcService());
    dispatch(resetNetworkingForNextMatchAction());
  };

const resetNetworkingForNextMatchAction: () => ResetNetworkingForNextMatchAction =
  () => ({
    type: NetworkingActionTypes.RESET_NETWORKING_FOR_NEXT_MATCH,
  });

const resetRemoteNetworkingAction: () => ResetRemoteNetworkingAction = () => ({
  type: NetworkingActionTypes.RESET_REMOTE_NETWORKING,
});

export const muteLocalAudio = () => {
  return (
    dispatch: ApplicationThunkDispatch,
    _: () => RootState,
    { getRtc }: ExtraArgument
  ) => {
    const isSuccess = getRtc().networkingRtcService.setAudioMuted(true);
    if (isSuccess) {
      dispatch(muteLocalAudioAction());
    }
  };
};

const muteLocalAudioAction: () => MuteLocalAudioAction = () => ({
  type: NetworkingActionTypes.MUTE_LOCAL_AUDIO,
});

export const unmuteLocalAudio = () => {
  return (
    dispatch: ApplicationThunkDispatch,
    _: () => RootState,
    { getRtc }: ExtraArgument
  ) => {
    const isSuccess = getRtc().networkingRtcService.setAudioMuted(false);
    if (isSuccess) {
      dispatch(unmuteLocalAudioAction());
    }
  };
};

const unmuteLocalAudioAction: () => UnmuteLocalAudioAction = () => ({
  type: NetworkingActionTypes.UNMUTE_LOCAL_AUDIO,
});

export const muteLocalVideo = () => {
  return (
    dispatch: ApplicationThunkDispatch,
    _: () => RootState,
    { getRtc }: ExtraArgument
  ) => {
    const isSuccess = getRtc().networkingRtcService.setVideoMuted(true);
    if (isSuccess) {
      dispatch(muteLocalVideoAction());
    }
  };
};

const muteLocalVideoAction: () => MuteLocalVideoAction = () => ({
  type: NetworkingActionTypes.MUTE_LOCAL_VIDEO,
});

export const unmuteLocalVideo = () => {
  return (
    dispatch: ApplicationThunkDispatch,
    _: () => RootState,
    { getRtc }: ExtraArgument
  ) => {
    const isSuccess = getRtc().networkingRtcService.setVideoMuted(false);
    if (isSuccess) {
      dispatch(unmuteLocalVideoAction());
    }
  };
};

const unmuteLocalVideoAction: () => UnmuteLocalVideoAction = () => ({
  type: NetworkingActionTypes.UNMUTE_LOCAL_VIDEO,
});

export const muteRemoteAudio: () => MuteRemoteAudioAction = () => ({
  type: NetworkingActionTypes.MUTE_REMOTE_AUDIO,
});

export const unmuteRemoteAudio: () => UnmuteRemoteAudioAction = () => ({
  type: NetworkingActionTypes.UNMUTE_REMOTE_AUDIO,
});

export const muteRemoteVideo: () => MuteRemoteVideoAction = () => ({
  type: NetworkingActionTypes.MUTE_REMOTE_VIDEO,
});

export const unmuteRemoteVideo: () => UnmuteRemoteVideoAction = () => ({
  type: NetworkingActionTypes.UNMUTE_REMOTE_VIDEO,
});

export const switchCamera =
  (currentDeviceId: string) =>
  (_1: unknown, _2: unknown, { getRtc }: ExtraArgument): Promise<void> => {
    const service = getRtc().networkingRtcService;
    if (service == null) {
      return Promise.resolve();
    }

    return service.switchCamera(currentDeviceId);
  };

export const switchMicrophone =
  (currentDeviceId: string) =>
  (_1: unknown, _2: unknown, { getRtc }: ExtraArgument): Promise<void> => {
    const service = getRtc().networkingRtcService;
    if (service == null) {
      return Promise.resolve();
    }

    return service.switchMicrophone(currentDeviceId);
  };

export const switchSpeakers =
  (currentDeviceId: string) =>
  (_1: unknown, _2: unknown, { getRtc }: ExtraArgument): Promise<void> => {
    const service = getRtc().networkingRtcService;
    if (service == null) {
      return Promise.resolve();
    }

    return service.switchSpeakers(currentDeviceId);
  };

export const initializeRtcService =
  (args: CreateRtcServiceArgs) =>
  async (
    _1: ApplicationThunkDispatch,
    _2: () => RootState,
    { getRtc }: ExtraArgument
  ): Promise<void> => {
    const rtc = getRtc();
    const rtcService = await createRtcService(args);
    rtc.networkingRtcService = rtcService;
  };

export const resetRtcService =
  () =>
  async (
    _1: ApplicationThunkDispatch,
    _2: () => RootState,
    { getRtc }: ExtraArgument
  ): Promise<void> => {
    const rtc = getRtc();
    rtc.networkingRtcService?.destroy();
    rtc.networkingRtcService = null;
  };

export const createStream =
  (streamId: string) =>
  async (
    dispatch: ApplicationThunkDispatch,
    getState: () => RootState,
    { getRtc }: ExtraArgument
  ): Promise<void> => {
    const { cameraLabel, microphoneLabel } = getState().device;
    await getRtc().networkingRtcService.createStream({
      streamId,
      cameraLabel,
      microphoneLabel,
    });
    dispatch(setLocalStreamId(streamId));
  };

export const joinRoom =
  (roomId: string) =>
  async (
    _1: ApplicationThunkDispatch,
    _2: () => RootState,
    { getRtc }: ExtraArgument
  ): Promise<void> => {
    const config = {
      roomId,
      roomType: RoomType.Networking,
    };
    await getRtc().networkingRtcService.joinRoom(config);
  };

export const leaveRoom =
  () =>
  async (
    dispatch: ApplicationThunkDispatch,
    _: () => RootState,
    { getRtc }: ExtraArgument
  ): Promise<void> => {
    await getRtc().networkingRtcService?.leaveRoom();

    dispatch(resetRemoteNetworkingAction());
    dispatch(updateRemoteStreamId());
  };

export const detectNoShow =
  () =>
  (_: ApplicationThunkDispatch, getState: () => RootState): boolean => {
    const remoteStreamId = getState().networking.remoteStreamId;
    return !remoteStreamId;
  };

export const publishStream =
  () =>
  async (
    _1: ApplicationThunkDispatch,
    _2: () => RootState,
    { getRtc }: ExtraArgument
  ): Promise<void> => {
    await getRtc().networkingRtcService.publishStream();
  };

export const setNetworkingDuration = ({
  eventId,
  eventShareableId,
  networkingDuration,
}: {
  eventId: string;
  eventShareableId: string;
  networkingDuration: number;
}) => {
  return (_1: unknown, _2: unknown, { getFirestore }: ExtraArgument) => {
    return updateEventNetworkingDuration(eventShareableId, networkingDuration)
      .then(() =>
        getFirestore().collection('events').doc(eventId).set(
          {
            networkingDuration,
          },
          { merge: true }
        )
      )
      .catch((err) => {
        const errorMessage = formatFirestoreError(
          'actions',
          'setNetworkingDuration',
          err
        );
        console.log(errorMessage);
        throw Error(errorMessage);
      });
  };
};

export const getNetworkQuality =
  () =>
  (
    _1: ApplicationThunkDispatch,
    _2: () => RootState,
    { getRtc }: ExtraArgument
  ) => {
    const { networkingRtcService } = getRtc();

    if (!networkingRtcService) {
      return {};
    }

    const uid = networkingRtcService.getLocalStreamId();

    return {
      ...networkingRtcService.getRemoteNetworkQuality(),
      [uid]: networkingRtcService.getLocalNetworkQuality(),
    };
  };
