import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { AuthActions, AuthActionTypes, AuthState } from './types';

const initialState: AuthState = {
  accountId: null,
  availableOrganizationMembers: null,
  currentOrganizationMember: null,
};

export const auth: Reducer<AuthState> = produce(
  (draft: Draft<AuthState>, action: AuthActions) => {
    switch (action.type) {
      case AuthActionTypes.SET_ACCOUNT_ID:
        draft.accountId = action.accountId;
        break;

      case AuthActionTypes.SET_CURRENT_ORGANIZATION_MEMBER:
        draft.currentOrganizationMember = action.currentOrganizationMember;
        break;

      case AuthActionTypes.SET_AVAILABLE_ORGANIZATION_MEMBERS:
        draft.availableOrganizationMembers =
          action.availableOrganizationMembers;
        break;
    }
  },
  initialState
);
