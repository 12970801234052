import { LocalStreamState } from '@venue/types/agora';
import { ScreenShareStatus } from '@venue/types/stage';
import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { StreamsActions, StreamsActionTypes, StreamsState } from './types';

const initialState: StreamsState = {
  clientId: null,
  localStreamId: null,
  remoteStreamIds: [],
  localStreamState: LocalStreamState.Idle,
  screenShareClientId: null,
  localScreenStreamId: null,
  remoteScreenStreamId: null,
  audioMutedMap: {},
  videoMutedMap: {},
  disconnectedMap: {},
  screenShareStatus: ScreenShareStatus.Available,
  recordingStatuses: [],
  channelName: null,
};

export const streams: Reducer<StreamsState> = produce(
  (draft: Draft<StreamsState>, action: StreamsActions) => {
    switch (action.type) {
      case StreamsActionTypes.SET_AGORA_CLIENT:
        draft.clientId = action.clientId;
        break;
      case StreamsActionTypes.SET_SCREEN_SHARE_AGORA_CLIENT:
        draft.screenShareClientId = action.clientId;
        break;
      case StreamsActionTypes.SET_LOCAL_STREAM:
        draft.localStreamId = action.streamId;
        break;
      case StreamsActionTypes.SET_REMOTE_STREAMS:
        draft.remoteStreamIds = action.streamIds;
        break;
      case StreamsActionTypes.SET_LOCAL_STREAM_STATE:
        draft.localStreamState = action.state;
        break;
      case StreamsActionTypes.SET_LOCAL_SCREEN_STREAM:
        draft.localScreenStreamId = action.streamId;
        break;
      case StreamsActionTypes.SET_REMOTE_SCREEN_STREAM:
        draft.remoteScreenStreamId = action.streamId;
        break;
      case StreamsActionTypes.SET_AUDIO_MUTED_MAP:
        draft.audioMutedMap[action.streamId] = action.isMuted;
        break;
      case StreamsActionTypes.SET_VIDEO_MUTED_MAP:
        draft.videoMutedMap[action.streamId] = action.isMuted;
        break;
      case StreamsActionTypes.SET_DISCONNECTED_MAP:
        draft.disconnectedMap = action.disconnectedMap;
        break;
      case StreamsActionTypes.SET_SCREEN_SHARE_STATUS:
        draft.screenShareStatus = action.status;
        break;
      case StreamsActionTypes.SET_RECORDING_STATUSES:
        draft.recordingStatuses = action.statuses;
        break;
      case StreamsActionTypes.SET_CHANNEL_NAME:
        draft.channelName = action.channelName;
        break;
      case StreamsActionTypes.CLEAR_AUDIO_MUTED_MAP:
        draft.audioMutedMap = {};
        break;
      case StreamsActionTypes.CLEAR_VIDEO_MUTED_MAP:
        draft.videoMutedMap = {};
        break;
    }
  },
  initialState
);
